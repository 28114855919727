const AnimatedVan = () => {
    let observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            const animatedVan = entry.target;

            if (entry.isIntersecting) {
                animatedVan.classList.add('animate');
                return;
            }
        });
    });

    let animatedVanHome = document.querySelector('.animated-van-home');
    let animatedVanModal = document.querySelector('.animated-van-modal');

    if (animatedVanHome) {
        observer.observe(animatedVanHome);
    }

    if (animatedVanModal) {
        observer.observe(animatedVanModal);
    }
}

export default AnimatedVan
