const Modal = () => {
    let body = document.querySelector('body')
    let modal = document.querySelector('.modal')

    if (!modal) {
        return
    }

    let modalContent = modal.querySelector('.content')

    if (modal.hasAttribute('data-hide') || !shouldShowModal()) {
        modal.style.display = 'none'
        return
    }

    showModal()

    modal.addEventListener('click', (event) => {
        if (event.target.classList.contains('close-button') || !modalContent.contains(event.target)) {
            closeModal()
        }
    })

    function showModal() {
        modal.style.display = 'flex'

        setTimeout(() => {
            body.style.overflowY = 'hidden'
            modal.style.opacity = 1
        }, 500)
    }

    function closeModal() {
        body.style.overflowY = 'scroll'
        modal.style.opacity = 0

        setTimeout(() => {
            modal.style.display = 'none'
        }, 500)
    }

    function shouldShowModal() {
        let now = Math.round(Date.now() / 1000)

        if (localStorage.getItem('show_move_modal_at') && now > localStorage.getItem('show_move_modal_at')) {
            localStorage.removeItem('show_move_modal_at')
        }

        if (!localStorage.getItem('show_move_modal_at')) {
            /**
             * The last multiplier states the amount of days the modal should stay away.
             */
            localStorage.setItem('show_move_modal_at', now + (3600 * 24 * 1))

            return true
        }

        return false
    }
}

export default Modal
